<template>
    <div class="ml_pc_report">
        <el-row>

            <!-- 车况检测 -->
            <el-col :span="24" class="ml_pc_report_title">
                车况检测
            </el-col>
            <el-col :span="24">
                <div class="ml_detection_level_list">
                    <div class="ml_detection_level_item">
                        综合 {{carlevel}}级 <img @click="showExplainPopu(3)" class="ml_icon_img" src="../../assets/008.png" />
                    </div>
                    <div class="ml_detection_level_item">
                        外观 {{appearancescore}}分 <img @click="showExplainPopu(0)" class="ml_icon_img" src="../../assets/008.png" />
                    </div>
                    <div class="ml_detection_level_item">
                        骨架 {{skeletonscore}}级 <img @click="showExplainPopu(1)" class="ml_icon_img" src="../../assets/008.png" />
                    </div>
                    <div class="ml_detection_level_item">
                        内饰 {{interiorscore}}分 <img @click="showExplainPopu(2)" class="ml_icon_img" src="../../assets/008.png" />
                    </div>
                    <div class="ml_detection_level_item">
                        机电 {{jdcount}}项
                    </div>
                    <div class="ml_detection_level_item">
                        水渍残留点 {{szclcount}}项
                    </div>
                </div>
            </el-col>

            <!-- 外观检测 -->
            <el-col :span="24" v-if="wgDetection">
                <el-row class="ml_pc_report_content_title">
                    <el-col :span="8">外观检测</el-col>
                    <el-col :span="8" style="text-align:center;color:#ff7901;">{{wgDetection.score_remark}}</el-col>
                    <el-col :span="8" style="text-align:right;">
                        <img v-if="wgDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        {{wgDetection.e_count}}项异常
                    </el-col>
                </el-row>
                <!-- 颜色说明 -->
                <div class="ml_detection_color_list">
                    <div class="ml_detection_color_list_item" v-for="(item,index) in wgDetection.crlist" :key="index">
                        <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
                        {{item.crname}}
                    </div>
                </div>
                <!-- 缺陷图片轮播 -->
                <componentDetectionImgPC :baseData="wgDetection"></componentDetectionImgPC>

                <!-- 检测缺陷项 -->
                <componentDetectionItemPC :baseData="wgDetection"></componentDetectionItemPC>
            </el-col>

            <!-- 骨架检测 -->
            <el-col :span="24" v-if="gjDetection">
                <el-row class="ml_pc_report_content_title">
                    <el-col :span="8">骨架检测</el-col>
                    <el-col :span="8" style="text-align:center;color:#ff7901;">{{gjDetection.score_remark}}</el-col>
                    <el-col :span="8" style="text-align:right;">
                        <img v-if="gjDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        {{gjDetection.e_count}}项异常
                    </el-col>
                </el-row>
                <!-- 颜色说明 -->
                <div class="ml_detection_color_list">
                    <div class="ml_detection_color_list_item" v-for="(item,index) in gjDetection.crlist" :key="index">
                        <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
                        {{item.crname}}
                    </div>
                </div>
                <!-- 缺陷图片轮播 -->
                <componentDetectionImgPC :baseData="gjDetection"></componentDetectionImgPC>

                <!-- 检测缺陷项 -->
                <componentDetectionItemPC :baseData="gjDetection"></componentDetectionItemPC>
            </el-col>

            <!-- 内饰检测 -->
            <el-col :span="24" v-if="nsDetection">
                <el-row class="ml_pc_report_content_title">
                    <el-col :span="8">内饰检测</el-col>
                    <el-col :span="8" style="text-align:center;color:#ff7901;">{{nsDetection.score_remark}}</el-col>
                    <el-col :span="8" style="text-align:right;">
                        <img v-if="nsDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        {{nsDetection.e_count}}项异常
                    </el-col>
                </el-row>
                <!-- 颜色说明 -->
                <div class="ml_detection_color_list">
                    <div class="ml_detection_color_list_item" v-for="(item,index) in nsDetection.crlist" :key="index">
                        <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
                        {{item.crname}}
                    </div>
                </div>

                <!-- 缺陷图片轮播 -->
                <componentDetectionImgPC :baseData="nsDetection"></componentDetectionImgPC>

                <!-- 检测缺陷项 -->
                <componentDetectionItemPC :baseData="nsDetection"></componentDetectionItemPC>
            </el-col>

            <!-- 机电检测 -->
            <el-col :span="24" v-if="jdDetection">
                <el-row class="ml_pc_report_content_title">
                    <el-col :span="8">机电检测</el-col>
                    <el-col :span="16" style="text-align:right;">
                        <img v-if="jdDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        {{jdDetection.e_count}}项异常
                    </el-col>
                </el-row>
                <el-row style="padding: 20px;" v-if="jdDetectionImgList.length>0">
                    <el-col :span="12" :offset="6">
                        <el-carousel :autoplay="false" indicator-position="none" arrow="always">
                            <el-carousel-item v-for="(item,index) in jdDetectionImgList" :key="index">
                                <img :src="item.cfg_att_url+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" :alt="item.name" class="ml_report_carimg" @click="onPreview(jdDetectionImgList,index)" />
                                <div class="ml_pc_report_imgfooter" v-if="item.name">
                                    {{item.name}}
                                </div>
                            </el-carousel-item>
                        </el-carousel>
                    </el-col>
                </el-row>
                <el-row style="padding:0 20px;">
                    <template v-for="(item,index) in jdDetection.clist">
                        <el-col :span="12" :key="index">
                            <el-col :span="4" style="padding-top:10px;"> {{item.name}}</el-col>
                            <el-col :span="20" style="padding-top:10px;">
                                <template v-for="(items,indexs) in item.dlist">
                                    <el-tag type="info" :key="indexs" effect="plain" size="small" style="margin-right:10px;">
                                        {{ items.name }}
                                    </el-tag>
                                </template>
                            </el-col>
                        </el-col>
                    </template>
                </el-row>
            </el-col>

            <!-- 水渍残留点 -->
            <el-col :span="24" v-if="szDetection">
                <el-row>
                    <el-col :span="8" class="ml_pc_report_title">水渍残留点</el-col>
                    <el-col :span="16" style="text-align:right;padding:20px 10px;">
                        <img v-if="szDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
                        {{szDetection.e_count}}项异常
                    </el-col>
                    <el-col :span="24" v-if="iscarwaterin==='1'||iscarwaterin==='0'" style="padding-bottom:15px;">
                        车辆是否曾进水(漏水/涉水/水泡)：{{iscarwaterin==1?'是':'否'}}
                    </el-col>
                </el-row>

                <!-- 检测缺陷项 -->
                <componentDetectionItemPC :baseData="szDetection"></componentDetectionItemPC>

            </el-col>

            <!-- 综合描述 -->
            <el-col :span="24" class="ml_pc_report_title">
                综合描述
            </el-col>
            <el-col :span="24" class="ml_pc_report_summary">
                {{detectionremark}}
            </el-col>
        </el-row>

        <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="viewerArray" />

        <componentRule ref="componentRule"></componentRule>
    </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import componentRule from '@/components/component_pc/component_rule_pc'
import componentDetectionItemPC from '@/components/component_pc/component_detection_item_pc'
import componentDetectionImgPC from '@/components/component_pc/component_detection_img_pc'

export default {
    props: {
        reportData: {
            type: Object,
            default: null
        }
    },
    components: {
        ElImageViewer,
        componentRule,
        componentDetectionItemPC,
        componentDetectionImgPC
    },
    data() {
        return {
            wgDetection: null,
            gjDetection: null,
            nsDetection: null,
            jdDetection: null,
            szDetection: null,

            detectionremark: '',

            carlevel: '',
            skeletonscore: '',
            appearancescore: '',
            interiorscore: '',
            jdcount: '',
            szclcount: '',

            showViewer: false,
            viewerArray: [],

            jdDetectionImgList: [],
            iscarwaterin: '', //车辆是否曾进水(漏水/涉水/水泡)
        }
    },
    mounted() {
        const that = this
        that.detectionremark = that.reportData.detectionremark || '-'
        that.carlevel = that.reportData.carlevel || '-'
        that.skeletonscore = that.reportData.skeletonscore || '-'
        that.appearancescore = that.reportData.appearancescore || '-'
        that.interiorscore = that.reportData.interiorscore || '-'
        that.iscarwaterin = that.reportData.iscarwaterin
        that.reportData.data.forEach(element => {
            switch (element.part_code) {
                case 'P01':
                    // 骨架
                    that.gjDetection = element
                    break
                case 'P02':
                    // 外观
                    that.wgDetection = element
                    break
                case 'P03':
                    // 内饰
                    that.nsDetection = element
                    break
                case 'M013':
                    // 机电
                    that.jdDetection = element

                    that.jdDetectionImgList = []

                    that.$nextTick(function () {
                        that.jdDetection.clist.forEach(element => {
                            if (element.defectlist) {
                                element.defectlist.forEach(item => {
                                    if (item.defectimage) {
                                        item.defectimage.forEach((url, index) => {
                                            that.jdDetectionImgList.push({
                                                name:
                                                    element.name + item.defectname + '图片' + (index + 1),
                                                cfg_att_url: url
                                            })
                                        })
                                    }
                                })
                            }
                        })
                    })

                    that.jdcount = that.jdDetection.e_count
                    break
                case '-1':
                    // 水渍残留点
                    that.szDetection = element
                    that.szclcount = that.szDetection.e_count
                    break
            }
        })
    },
    methods: {
        showExplainPopu(index) {
            this.$refs.componentRule.showExplainPopu(index)
        },
        closeViewer() {
            this.showViewer = false
        },
        onPreview(climages, i) {
            this.viewerArray = []
            let _img = []
            climages.forEach((element, index) => {
                if (index >= i) {
                    this.viewerArray.push(
                        element.cfg_att_url +
                        '?x-oss-process=image/resize,w_1280,h_960/quality,q_90'
                    )
                } else {
                    _img.push(
                        element.cfg_att_url +
                        '?x-oss-process=image/resize,w_1280,h_960/quality,q_90'
                    )
                }
            })
            this.viewerArray = this.viewerArray.concat(_img)
            this.showViewer = true
        }
    }
}
</script>

<style lang="scss">
.ml_pc_report {
    padding: 10px 0 50px 0;
    width: 1200px;
    margin: 0 auto;

    .el-carousel__item {
        .ml_pc_report_imgfooter {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            padding: 5px 0;
            text-align: center;
            background-color: rgba(0, 0, 0, 0.8);
            color: #fff;
        }
    }

    .ml_pc_report_detection {
        position: absolute;
        bottom: 40px;
        right: 10px;
        z-index: 999;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.6);
        text-align: center;

        .ml_pc_report_detection_title {
            border-right: 1px solid #fff;
            border-bottom: 1px solid #fff;
            padding: 5px;

            &:nth-child(4n + 4) {
                border-right: 0;
            }
        }

        .ml_pc_report_detection_value {
            border-right: 1px solid #fff;
            padding: 5px 0;
            color: #f1cc32;

            &:last-child {
                border: 0;
            }
        }
    }

    .ml_report_carimg {
        object-fit: cover;
        height: 100%;
        width: 100%;
        cursor: pointer;
    }

    .ml_pc_report_caraddress {
        // color: #8D8D8D;
        padding: 10px 0 20px 0;
    }

    .ml_pc_report_title {
        padding: 20px 0;
        font-size: 16px;

        &::before {
            content: "▌";
            color: #ff7901;
        }
    }

    .ml_detection_level_list {
        width: 50%;
        display: flex;
        color: #ff7901;
        text-align: center;
        margin: 0 auto;
        border: 1px solid #ff7901;
        border-radius: 3px;
        .ml_detection_level_item {
            flex: 1;
            padding: 5px 0;
            border-right: 1px solid #ff7901;
            &:last-child {
                border: 0;
            }

            .ml_icon_img {
                display: inline-block;
                width: 12px;
                vertical-align: middle;
                margin-top: -3px;
                margin-left: 5px;
                cursor: pointer;
            }
        }
    }

    .ml_pc_report_summary {
        margin: 10px 0;
        padding: 20px;
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
    }

    .el-icon-circle-close:before {
        color: #fff;
        font-size: 24px;
    }

    .ml_pc_report_content_title {
        background-color: #f6f6f6;
        padding: 10px 20px;
        margin-top: 20px;
    }

    .ml_detection_color_list {
        padding: 20px 0 10px 0;
        display: flex;
        color: #7d7d7d;

        .ml_detection_color_list_item {
            flex: 1;
            text-align: center;

            .ml_color_circle {
                width: 12px;
                height: 12px;
                border-radius: 100%;
                margin-right: 10px;
                display: inline-block;
            }
        }
    }

    .el-carousel__container {
        height: 438.75px;
    }

    .ml_pc_detection_card {
        .ml_pc_detection_row {
            border-top: 1px solid #f1f1f1;
            border-left: 1px solid #f1f1f1;
        }

        .el-col-8 {
            border-bottom: 1px solid #f1f1f1;
            border-right: 1px solid #f1f1f1;

            .el-col-12 {
                padding: 10px;

                &:first-child {
                    background-color: #f8f8f8;
                }
            }
        }
    }
}
</style>
