<!--
 * @Descripttion : 检测缺陷图片轮播+点位
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-12 11:57:31
 * @LastEditors  : min
 * @LastEditTime : 2022-11-10 14:38:37
-->

<template>
  <div>
    <el-row :gutter="20" style="padding:20px 0;">
      <el-col :span="12">
        <!-- 检测点位 -->
        <div class="ml_pc_detection_img">
          <!-- 外观 -->
          <img src="../../assets/001.png" v-if="baseData.part_code==='P02'" @click.stop="" />
          <!-- 骨架 -->
          <img src="../../assets/002.png" v-if="baseData.part_code==='P01'" @click.stop="" />
          <!-- 内饰 -->
          <img src="../../assets/003.png" v-if="baseData.part_code==='P03'" @click.stop="" />
          <template v-for="(item,index) in baseData.clist">
            <div v-if="item.is_check==1&&item.x&&item.y" class="ml_circle" :style="{'left':item.x*100+'%','top':item.y*100+'%','background-color':item.crvalue}" @click="viewDetection(item)" :key="index">
              <div class="ml_circle_outside" v-if="index==swipeIndex">
                <div class="ml_circle_inside" :style="{'background-color':item.crvalue}"></div>
              </div>
            </div>
          </template>
        </div>
      </el-col>
      <el-col :span="12">
        <!-- 检测图片 -->
        <template v-if="baseData.e_count>0">
          <el-carousel :autoplay="false" indicator-position="none" arrow="always" ref="carousel" @change="onChangeCarousel($event)" v-if="getDefectImgCount()">
            <template v-for="items in baseData.clist">
              <template v-for="(item,index) in items.listimg">
                <el-carousel-item :key="item.id" v-if="item.defect_image">
                  <img :src="item.defect_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection" @click="showDetection(baseData.clist,item.id)">
                  <div class="ml_detection_name">
                    {{items.name}} ({{index+1}}/{{items.listimg.length}})
                  </div>
                  <template v-for="(items,indexs) in item.dlist">
                    <template v-if="items.x>0&&items.y>0">
                      <template v-if="items.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </template>
            </template>
          </el-carousel>
        </template>
        <template v-else>
          <img src="../../assets/007.png" style="width:100%;" />
        </template>
      </el-col>
    </el-row>

    <!-- 查看图片缺损信息 -->
    <el-dialog :visible.sync="viewImgShow" width="780px" class="ml_view_img">
      <el-carousel v-if="viewImgShow" :autoplay="false" indicator-position="none" arrow="always" :initial-index="viewImgIndex" @change="onChangeViewImg">
        <el-carousel-item v-for="(item,index) in viewImgs" :key="index">
          <img :src="item.defect_image" class="ml_report_carimg_detection">

          <template v-if="!hiddenText">
            <template v-for="(items,indexs) in item.dlist">
              <template v-if="items.x>0&&items.y>0">
                <template v-if="items.x>0.5">
                  <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                    <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                    <div class="ml_dot_detection_text">
                      <span class="ml_dot_text">
                        {{items.name}}
                      </span>
                      <span class="ml_dot_triangle"></span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                    <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                    <div class="ml_dot_detection_text">
                      <span class="ml_dot_triangle"></span>
                      <span class="ml_dot_text">
                        {{items.name}}
                      </span>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </template>
        </el-carousel-item>
      </el-carousel>
      <div slot="footer" class="ml_view_img_dialog_footer">
        <template v-if="viewChangeInfo">
          <el-row class="ml_view_img_name">
            <el-col :span="16">
              {{viewChangeInfo.title}}
            </el-col>
            <el-col :span="8" style="color:#858585;text-align:right;">
              ({{viewChangeInfo.index}}/{{viewChangeInfo.count}})
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <template v-for="(item,index) in viewChangeInfo.crlist">
                <div :key="index">
                  <div :style="{'background-color':item.crvalue}" class="ml_view_img_title" v-if="item.crname">{{item.crname}}</div>
                  <div class="ml_view_img_value">{{item.defectname}}</div>
                </div>
              </template>
              <div v-if="!viewChangeInfo.crlist">-</div>
            </el-col>
            <el-col :span="4" style="text-align:right;">
              <el-checkbox v-model="hiddenText">关文字</el-checkbox>
            </el-col>
          </el-row>
        </template>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: {
    baseData: {
      type: Object
    }
  },
  data() {
    return {

      swipeIndex: 0,

      viewImgShow: false,
      viewImgIndex: 0,
      viewImgs: [],
      viewChangeIndex: 0,
      viewChangeInfo: 0,

      hiddenText: false //  隐藏图片打点文字
    }
  },
  methods: {
    showDetection(obj, id) {
      console.log(obj, id)
      let _imgArray = []
      obj.forEach(element => {
        element.listimg.forEach((item, index) => {
          _imgArray.push({
            title: element.name,
            defect_image: item.defect_image +
              '?x-oss-process=image/resize,w_1280,h_960/quality,q_90',
            dlist: item.dlist,
            crlist: item.crlist,
            index: index + 1,
            count: element.listimg.length
          })
          if (item.id === id) {
            this.viewImgIndex = _imgArray.length - 1
            this.viewChangeIndex = _imgArray.length - 1
            this.viewChangeInfo = _imgArray[_imgArray.length - 1]
          }
        })
      })
      this.viewImgs = _imgArray
      this.viewImgShow = true
    },
    onChangeViewImg(i) {
      this.viewChangeIndex = i
      this.viewChangeInfo = this.viewImgs[i]
    },
    viewDetection(obj) {
      console.log(this.baseData, obj)
      const that = this
      let _index = -1
      for (let i in that.baseData.clist) {
        for (let j in that.baseData.clist[i].listimg) {
          _index++
          if (that.baseData.clist[i].code === obj.code) {
            that.swipeIndex = i
            if (that.baseData.clist[i].listimg[j].defect_image) {
              that.$refs.carousel.setActiveItem(_index) // 右侧轮播图片
            }
            console.log(that.swipeIndex)
            return false
          }
        }
      }
    },
    onChangeCarousel(index) {
      const that = this
      let _index = -1
      for (let i in that.baseData.clist) {
        for (let j in that.baseData.clist[i].listimg) {
          _index++
          if (index === _index) {
            that.swipeIndex = i
            console.log(that.swipeIndex)
            return false
          }
        }
      }
    },
    getDefectImgCount() {
      // 获取图片总数
      const that = this
      let _imgCount = 0
      for (let i in that.baseData.clist) {
        for (let j in that.baseData.clist[i].listimg) {
          if (that.baseData.clist[i].listimg[j].defect_image) {
            _imgCount++
          }
        }
      }
      return _imgCount
    },
  }
}
</script>
<style lang="scss" scoped>
.ml_pc_detection_img {
  position: relative;

  img {
    width: 100%;
  }

  .ml_circle {
    width: 12px;
    height: 12px;
    text-align: center;
    border-radius: 100%;
    position: absolute;
    color: #fff;
    cursor: pointer;
  }
}

.ml_report_carimg_detection {
  width: 100%;
  cursor: pointer;
  height: 100%;
}

.ml_pc_report_table {
  border-collapse: collapse;
  text-align: center;
  width: 100%;
  tr {
    &:nth-child(2n + 2) {
      background-color: #f8f8f8;
    }
    td {
      padding: 15px 0;
      border: 1px solid #f1f1f1;
      line-height: 14px;
    }
  }
}

.ml_pc_report_name {
  font-size: 20px;
  font-weight: bold;
  padding: 10px 0 20px 0;
}

.ml_pc_report_carinfo {
  background-color: #f8f8f8;
  padding: 15px 20px;
  text-align: center;
  margin: 20px 0;

  .el-col-6 {
    position: relative;

    &::after {
      content: "|";
      position: absolute;
      right: 0;
      top: 50%;
      color: #d3d3d3;
      font-size: 22px;
      font-weight: 50;
      margin-top: -11px;
    }

    &:last-child {
      &::after {
        content: "";
      }
    }

    div {
      &:first-child {
        padding-bottom: 10px;
      }

      &:last-child {
        color: #8d8d8d;
        font-size: 12px;
      }
    }
  }
}

.ml_detection_name {
  text-align: center;
  padding: 5px 0;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: #fff;
}

.ml_dot_detection {
  position: absolute;
  z-index: 9999;
  margin-top: -9px;
  margin-left: -5px;

  .ml_dot_color {
    color: #797979;
    &::before {
      content: "●";
      font-size: 16px;
      padding-right: 8px;
    }
  }

  .ml_dot_detection_text {
    position: absolute;
    left: 15px;
    margin-top: -22px;
  }

  .ml_dot_triangle {
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-right: 13px solid rgba(0, 0, 0, 0.8);
      border-bottom: 13px solid transparent;
      display: inline-block;
    }
  }

  .ml_dot_text {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    height: 26px;
    line-height: 26px;
    display: inline-block;
    white-space: nowrap;
    padding: 0 8px;
    margin-left: 13px;
  }
}

.ml_dot_detection1 {
  position: absolute;
  z-index: 9999;

  .ml_dot_color {
    color: #797979;
    &::before {
      content: "●";
      font-size: 16px;
      padding-left: 8px;
    }
  }

  .ml_dot_detection_text {
    position: absolute;
    right: 30px;
    margin-top: -22px;
  }

  .ml_dot_triangle {
    &::before {
      content: "";
      position: absolute;
      width: 0;
      height: 0;
      border-top: 13px solid transparent;
      border-left: 13px solid rgba(0, 0, 0, 0.8);
      border-bottom: 13px solid transparent;
      display: inline-block;
    }
  }

  .ml_dot_text {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.8);
    height: 26px;
    line-height: 26px;
    display: inline-block;
    white-space: nowrap;
    padding: 0 8px;
  }
}

.ml_circle_outside {
  position: relative;
  width: 100%;
  height: 100%;

  .ml_circle_inside {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 12px;
    height: 12px;
    filter: alpha(opacity=30);
    -moz-opacity: 0.3;
    -khtml-opacity: 0.3;
    opacity: 0.3;
    border-radius: 100%;
    animation: mymove 1.5s infinite;
    -webkit-animation: mymove 1.5s infinite;
  }

  @keyframes mymove {
    from {
      width: 12px;
      height: 12px;
    }

    to {
      width: 24px;
      height: 24px;
    }
  }

  @-webkit-keyframes mymove {
    from {
      width: 12px;
      height: 12px;
    }

    to {
      width: 24px;
      height: 24px;
    }
  }
}

.ml_view_img {
  .el-carousel__container {
    height: 585px;
  }

  .el-dialog__body,
  .el-dialog__header {
    padding: 0;
  }

  .el-dialog__headerbtn {
    right: -30px;
    color: #fff;
    top: 0;

    .el-icon-close:before {
      color: #fff;
      font-size: 20px;
    }
  }

  .ml_view_img_dialog_footer {
    text-align: left;
  }

  .ml_view_img_name {
    padding: 10px 0;

    .el-col {
      font-size: 18px;
    }
  }

  .ml_view_img_title {
    display: inline-block;
    padding: 3px 10px;
    color: #fff;
    border-radius: 3px;
    font-size: 12px;
  }

  .ml_view_img_value {
    display: inline-block;
    padding: 3px 10px;
    margin: 5px;
    font-size: 12px;
    color: #858585;
  }
}
</style>
