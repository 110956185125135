<template>
  <div class="ml_pc_report">
    <el-row>

      <!-- 车况检测 -->
      <el-col :span="24" class="ml_pc_report_title">
        车况检测
      </el-col>
      <el-col :span="24">
        <div class="ml_detection_level_list">
          <div class="ml_detection_level_item">
            外观 {{appearancescore}}分 <img @click="showExplainPopu(0)" class="ml_icon_img" src="../../assets/008.png" />
          </div>
          <div class="ml_detection_level_item">
            骨架 {{skeletonscore}}级 <img @click="showExplainPopu(1)" class="ml_icon_img" src="../../assets/008.png" />
          </div>
          <div class="ml_detection_level_item">
            内饰 {{interiorscore}}分 <img @click="showExplainPopu(2)" class="ml_icon_img" src="../../assets/008.png" />
          </div>
          <div class="ml_detection_level_item">
            机电 {{jdcount}}项
          </div>
          <div class="ml_detection_level_item">
            水渍残留点 {{szclcount}}项
          </div>
        </div>
      </el-col>

      <!-- 外观检测 -->
      <el-col :span="24" v-if="wgDetection">
        <el-row class="ml_pc_report_content_title">
          <el-col :span="8">外观检测</el-col>
          <el-col :span="8" style="text-align:center;color:#ff7901;">{{wgDetection.score_remark}}</el-col>
          <el-col :span="8" style="text-align:right;">
            <img v-if="wgDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
            <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
            {{wgDetection.e_count}}项异常
          </el-col>
        </el-row>
        <div class="ml_detection_color_list">
          <div class="ml_detection_color_list_item" v-for="(item,index) in wgDetection.crlist" :key="index">
            <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
            {{item.crname}}
          </div>
        </div>
        <el-row :gutter="30" style="padding:20px 0;">
          <el-col :span="12" class="ml_pc_detection_img">
            <img src="../../assets/001.png" @click.stop="" />
            <template v-for="(item,index) in wgDetection.clist">
              <div v-if="item.is_check==1&&item.x&&item.y" class="ml_circle" :style="{'left':item.x*100+'%','top':item.y*100+'%','background-color':item.crvalue}" @click="viewDetection(0,index)" :key="index">
                <div class="ml_circle_outside" v-if="index==wgSwipeIndex">
                  <div class="ml_circle_inside" :style="{'background-color':item.crvalue}"></div>
                </div>
              </div>
            </template>
          </el-col>
          <el-col :span="12">
            <template v-if="wgDetection.e_count>0">
              <el-carousel :autoplay="false" indicator-position="none" arrow="always" ref="wgCarousel" @change="onChangeCarousel(0,$event)">
                <el-carousel-item v-for="(item,index) in wgDetection.clist" :key="index">
                  <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection" @click="showDetection(wgDetection.clist,index)">
                  <div class="ml_detection_name">
                    {{item.name}} ({{index+1}}/{{wgDetection.clist.length}})
                  </div>
                  <template v-for="(items,indexs) in item.dlist">
                    <template v-if="items.x>0&&items.y>0">
                      <template v-if="items.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </el-carousel>
            </template>
            <template v-else>
              <img src="../../assets/007.png" style="width:100%;" />
            </template>
          </el-col>
        </el-row>

        <el-row class="ml_pc_detection_list" v-if="wgDetection.e_count>0">
          <el-col :span="4" v-for="(item,index) in wgDetection.clist" :key="index">
            <el-popover placement="top-start" width="585" trigger="hover" popper-class="ml_pc_detection_list_popover">
              <el-carousel :autoplay="false" indicator-position="none" arrow="never">
                <el-carousel-item :key="index">
                  <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection">
                  <div class="ml_detection_name">
                    {{item.name}}
                  </div>
                  <template v-for="(items,indexs) in item.dlist">
                    <template v-if="items.x>0&&items.y>0">
                      <template v-if="items.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </el-carousel>

              <template slot="reference">
                <span>
                  <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                  {{item.name}}
                </span>
              </template>
            </el-popover>
          </el-col>

        </el-row>

      </el-col>

      <!-- 骨架检测 -->
      <el-col :span="24" v-if="gjDetection">
        <el-row class="ml_pc_report_content_title">
          <el-col :span="8">骨架检测</el-col>
          <el-col :span="8" style="text-align:center;color:#ff7901;">{{gjDetection.score_remark}}</el-col>
          <el-col :span="8" style="text-align:right;">
            <img v-if="gjDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
            <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
            {{gjDetection.e_count}}项异常
          </el-col>
        </el-row>
        <div class="ml_detection_color_list">
          <div class="ml_detection_color_list_item" v-for="(item,index) in gjDetection.crlist" :key="index">
            <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
            {{item.crname}}
          </div>
        </div>
        <el-row :gutter="30" style="padding:20px 0;">
          <el-col :span="12" class="ml_pc_detection_img">
            <img src="../../assets/002.png" @click.stop="" />
            <template v-for="(item,index) in gjDetection.clist">
              <div v-if="item.is_check==1&&item.x&&item.y" class="ml_circle" :style="{'left':item.x*100+'%','top':item.y*100+'%','background-color':item.crvalue}" @click="viewDetection(1,index)" :key="index">
                <div class="ml_circle_outside" v-if="index==gjSwipeIndex">
                  <div class="ml_circle_inside" :style="{'background-color':item.crvalue}"></div>
                </div>
              </div>
            </template>
          </el-col>
          <el-col :span="12">
            <template v-if="gjDetection.e_count>0">
              <el-carousel :autoplay="false" indicator-position="none" arrow="always" ref="gjCarousel" @change="onChangeCarousel(1,$event)">
                <el-carousel-item v-for="(item,index) in gjDetection.clist" :key="index">
                  <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection" @click="showDetection(gjDetection.clist,index)">
                  <div class="ml_detection_name">
                    {{item.name}} ({{index+1}}/{{gjDetection.clist.length}})
                  </div>
                  <template v-for="(items,indexs) in item.dlist">
                    <template v-if="items.x>0&&items.y>0">
                      <template v-if="items.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </el-carousel>
            </template>
            <template v-else>
              <img src="../../assets/007.png" style="width:100%;" />
            </template>
          </el-col>
        </el-row>

        <el-row class="ml_pc_detection_list" v-if="gjDetection.e_count>0">
          <el-col :span="4" v-for="(item,index) in gjDetection.clist" :key="index">
            <el-popover placement="top-start" width="585" trigger="hover" popper-class="ml_pc_detection_list_popover">
              <el-carousel :autoplay="false" indicator-position="none" arrow="never">
                <el-carousel-item :key="index">
                  <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection">
                  <div class="ml_detection_name">
                    {{item.name}}
                  </div>
                  <template v-for="(items,indexs) in item.dlist">
                    <template v-if="items.x>0&&items.y>0">
                      <template v-if="items.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </el-carousel>

              <template slot="reference">
                <span>
                  <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                  {{item.name}}
                </span>
              </template>
            </el-popover>
          </el-col>

        </el-row>
      </el-col>

      <!-- 内饰检测 -->
      <el-col :span="24" v-if="nsDetection">
        <el-row class="ml_pc_report_content_title">
          <el-col :span="8">内饰检测</el-col>
          <el-col :span="8" style="text-align:center;color:#ff7901;">{{nsDetection.score_remark}}</el-col>
          <el-col :span="8" style="text-align:right;">
            <img v-if="nsDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
            <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
            {{nsDetection.e_count}}项异常
          </el-col>
        </el-row>
        <div class="ml_detection_color_list">
          <div class="ml_detection_color_list_item" v-for="(item,index) in nsDetection.crlist" :key="index">
            <div class="ml_color_circle" :style="{'background-color':item.crvalue}"></div>
            {{item.crname}}
          </div>
        </div>
        <el-row :gutter="30" style="padding:20px 0;">
          <el-col :span="12" class="ml_pc_detection_img">
            <img src="../../assets/003.png" @click.stop="" />
            <template v-for="(item,index) in nsDetection.clist">
              <div v-if="item.is_check==1&&item.x&&item.y" class="ml_circle" :style="{'left':item.x*100+'%','top':item.y*100+'%','background-color':item.crvalue}" @click="viewDetection(2,index)" :key="index">
                <div class="ml_circle_outside" v-if="index==nsSwipeIndex">
                  <div class="ml_circle_inside" :style="{'background-color':item.crvalue}"></div>
                </div>
              </div>
            </template>
          </el-col>
          <el-col :span="12">
            <template v-if="nsDetection.e_count>0">
              <el-carousel :autoplay="false" indicator-position="none" arrow="always" ref="nsCarousel" @change="onChangeCarousel(2,$event)">
                <el-carousel-item v-for="(item,index) in nsDetection.clist" :key="index">
                  <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection" @click="showDetection(nsDetection.clist,index)">
                  <div class="ml_detection_name">
                    {{item.name}} ({{index+1}}/{{nsDetection.clist.length}})
                  </div>
                  <template v-for="(items,indexs) in item.dlist">
                    <template v-if="items.x>0&&items.y>0">
                      <template v-if="items.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </el-carousel>
            </template>
            <template v-else>
              <img src="../../assets/007.png" style="width:100%;" />
            </template>
          </el-col>
        </el-row>

        <el-row class="ml_pc_detection_list" v-if="nsDetection.e_count>0">
          <el-col :span="4" v-for="(item,index) in nsDetection.clist" :key="index">
            <el-popover placement="top-start" width="585" trigger="hover" popper-class="ml_pc_detection_list_popover">
              <el-carousel :autoplay="false" indicator-position="none" arrow="never">
                <el-carousel-item :key="index">
                  <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection">
                  <div class="ml_detection_name">
                    {{item.name}}
                  </div>
                  <template v-for="(items,indexs) in item.dlist">
                    <template v-if="items.x>0&&items.y>0">
                      <template v-if="items.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                          <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{items.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </el-carousel>

              <template slot="reference">
                <span>
                  <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                  {{item.name}}
                </span>
              </template>
            </el-popover>
          </el-col>

        </el-row>
      </el-col>

      <!-- 机电检测 -->
      <el-col :span="24" v-if="jdDetection">
        <el-row class="ml_pc_report_content_title">
          <el-col :span="8">机电检测</el-col>
          <el-col :span="16" style="text-align:right;">
            <img v-if="jdDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
            <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
            {{jdDetection.e_count}}项异常
          </el-col>
        </el-row>
        <el-row style="padding: 20px;" v-if="jdDetectionImgList.length>0">
          <el-col :span="12" :offset="6">
            <el-carousel :autoplay="false" indicator-position="none" arrow="always">
              <el-carousel-item v-for="(item,index) in jdDetectionImgList" :key="index">
                <img :src="item.cfg_att_url+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" :alt="item.name" class="ml_report_carimg" @click="onPreview(jdDetectionImgList,index)" />
                <div class="ml_pc_report_imgfooter" v-if="item.name">
                  {{item.name}}
                </div>
              </el-carousel-item>
            </el-carousel>
          </el-col>
        </el-row>
        <el-row style="padding:0 20px;">
          <template v-for="(item,index) in jdDetection.clist">
            <el-col :span="12" :key="index">
              <el-col :span="4" style="padding-top:10px;"> {{item.name}}</el-col>
              <el-col :span="20" style="padding-top:10px;">
                <template v-for="(items,indexs) in item.dlist">
                  <el-tag type="info" :key="indexs" effect="plain" size="small" style="margin-right:10px;">
                    {{ items.name }}
                  </el-tag>
                </template>
              </el-col>
            </el-col>
          </template>
        </el-row>
      </el-col>

      <!-- 水渍残留点 -->
      <el-col :span="24" v-if="szDetection">
        <el-row>
          <el-col :span="8" class="ml_pc_report_title">水渍残留点</el-col>
          <el-col :span="16" style="text-align:right;padding:20px 10px;">
            <img v-if="szDetection.e_count>0" src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
            <img v-else src="../../assets/005.png" style="display:inline-block;width:12px;margin-right:10px;" />
            {{szDetection.e_count}}项异常
          </el-col>
        </el-row>

        <el-row style="padding:0 20px;">
          <el-row class="ml_pc_detection_list" v-if="szDetection.e_count>0">
            <el-col :span="4" v-for="(item,index) in szDetection.clist" :key="index">
              <el-popover placement="top-start" width="585" trigger="hover" popper-class="ml_pc_detection_list_popover">
                <el-carousel :autoplay="false" indicator-position="none" arrow="never">
                  <el-carousel-item :key="index">
                    <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection">
                    <div class="ml_detection_name">
                      {{item.name}}
                    </div>
                    <template v-for="(items,indexs) in item.dlist">
                      <template v-if="items.x>0&&items.y>0">
                        <template v-if="items.x>0.5">
                          <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                            <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                            <div class="ml_dot_detection_text">
                              <span class="ml_dot_text">
                                {{items.name}}
                              </span>
                              <span class="ml_dot_triangle"></span>
                            </div>
                          </div>
                        </template>
                        <template v-else>
                          <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                            <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                            <div class="ml_dot_detection_text">
                              <span class="ml_dot_triangle"></span>
                              <span class="ml_dot_text">
                                {{items.name}}
                              </span>
                            </div>
                          </div>
                        </template>
                      </template>
                    </template>
                  </el-carousel-item>
                </el-carousel>

                <template slot="reference">
                  <span>
                    <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                    {{item.name}}
                  </span>
                </template>
              </el-popover>
            </el-col>

          </el-row>
        </el-row>

      </el-col>

      <!-- 综合描述 -->
      <el-col :span="24" class="ml_pc_report_title">
        综合描述
      </el-col>
      <el-col :span="24" class="ml_pc_report_summary">
        {{detectionremark}}
      </el-col>
    </el-row>

    <!-- 查看图片缺损信息 -->
    <el-dialog :visible.sync="viewImgShow" width="780px" class="ml_view_img">
      <el-carousel v-if="viewImgShow" :autoplay="false" indicator-position="none" arrow="always" :initial-index="viewImgIndex" @change="onChangeViewImg">
        <el-carousel-item v-for="(item,index) in viewImgs" :key="index">
          <img :src="item.detection_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection">
          <template v-if="!hiddenText">
            <template v-for="(items,indexs) in item.dlist">
              <template v-if="items.x>0&&items.y>0">
                <template v-if="items.x>0.5">
                  <div class="ml_dot_detection1" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                    <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                    <div class="ml_dot_detection_text">
                      <span class="ml_dot_text">
                        {{items.name}}
                      </span>
                      <span class="ml_dot_triangle"></span>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="ml_dot_detection" :style="{'left':items.x*100+'%','top':items.y*100+'%'}" :key="indexs">
                    <span class="ml_dot_color" :style="{'color':items.crvalue}"></span>
                    <div class="ml_dot_detection_text">
                      <span class="ml_dot_triangle"></span>
                      <span class="ml_dot_text">
                        {{items.name}}
                      </span>
                    </div>
                  </div>
                </template>
              </template>
            </template>
          </template>
        </el-carousel-item>
      </el-carousel>
      <div slot="footer" class="ml_view_img_dialog_footer">
        <template v-if="viewChangeInfo">
          <el-row class="ml_view_img_name">
            <el-col :span="16">
              {{viewChangeInfo.name}}
            </el-col>
            <el-col :span="8" style="color:#858585;text-align:right;">
              ({{viewChangeIndex+1}}/{{viewImgs.length}})
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="20">
              <template v-for="(item,index) in viewChangeInfo.crlist">
                <div :key="index">
                  <div :style="{'background-color':item.crvalue}" class="ml_view_img_title">{{item.crname}}</div>
                  <div class="ml_view_img_value">{{item.defectname}}</div>
                </div>
              </template>
              <div v-if="!viewChangeInfo.crlist">-</div>
            </el-col>
            <el-col :span="4" style="text-align:right;">
              <el-checkbox v-model="hiddenText">关文字</el-checkbox>
            </el-col>
          </el-row>
        </template>
      </div>
    </el-dialog>

    <el-image-viewer v-if="showViewer" :on-close="closeViewer" :url-list="viewerArray" />

    <componentRule ref="componentRule"></componentRule>
  </div>
</template>
<script>
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
import componentRule from '@/components/component_pc/component_rule_pc'

export default {
  props: {
    reportData: {
      type: Object,
      default: null
    }
  },
  components: {
    ElImageViewer,
    componentRule
  },
  data() {
    return {
      wgDetection: null,
      gjDetection: null,
      nsDetection: null,
      jdDetection: null,
      szDetection: null,

      wgSwipeIndex: 0,
      gjSwipeIndex: 0,
      nsSwipeIndex: 0,

      viewImgShow: false,
      viewImgIndex: 0,
      viewImgs: [],
      viewChangeIndex: 0,
      viewChangeInfo: 0,

      detectionremark: '',
      skeletonscore: '',
      appearancescore: '',
      interiorscore: '',
      jdcount: '',
      szclcount: '',

      showViewer: false,
      viewerArray: [],

      jdDetectionImgList: [],

      hiddenText: false //  隐藏图片打点文字
    }
  },
  mounted() {
    const that = this
    that.detectionremark = that.reportData.detectionremark || '-'
    that.skeletonscore = that.reportData.skeletonscore || '-'
    that.appearancescore = that.reportData.appearancescore || '-'
    that.interiorscore = that.reportData.interiorscore || '-'

    that.reportData.data.forEach(element => {
      switch (element.part_code) {
        case 'P01':
          // 骨架
          that.gjDetection = element
          break
        case 'P02':
          // 外观
          that.wgDetection = element
          break
        case 'P03':
          // 内饰
          that.nsDetection = element
          break
        case 'M013':
          // 机电
          that.jdDetection = element

          that.jdDetectionImgList = []

          that.$nextTick(function () {
            that.jdDetection.clist.forEach(element => {
              if (element.defectlist) {
                element.defectlist.forEach(item => {
                  if (item.defectimage) {
                    item.defectimage.forEach((url, index) => {
                      that.jdDetectionImgList.push({
                        name:
                          element.name + item.defectname + '图片' + (index + 1),
                        cfg_att_url: url
                      })
                    })
                  }
                })
              }
            })
          })

          that.jdcount = that.jdDetection.e_count
          break
        case '-1':
          // 水渍残留点
          that.szDetection = element
          that.szclcount = that.szDetection.e_count
          break
      }
    })
  },
  methods: {
    showExplainPopu(index) {
      this.$refs.componentRule.showExplainPopu(index)
    },
    closeViewer() {
      this.showViewer = false
    },
    onPreview(climages, i) {
      this.viewerArray = []
      let _img = []
      climages.forEach((element, index) => {
        if (index >= i) {
          this.viewerArray.push(
            element.cfg_att_url +
            '?x-oss-process=image/resize,w_1280,h_960/quality,q_90'
          )
        } else {
          _img.push(
            element.cfg_att_url +
            '?x-oss-process=image/resize,w_1280,h_960/quality,q_90'
          )
        }
      })
      this.viewerArray = this.viewerArray.concat(_img)
      this.showViewer = true
    },
    showDetection(obj, index) {
      console.log(obj, index)
      this.viewImgs = obj
      this.viewImgIndex = index
      this.viewChangeIndex = index
      this.viewChangeInfo = obj[index]
      this.viewImgShow = true
    },
    onChangeViewImg(i) {
      this.viewChangeIndex = i
      this.viewChangeInfo = this.viewImgs[i]
    },
    viewDetection(type, index) {
      const that = this
      switch (type) {
        case 0:
          that.wgSwipeIndex = index
          that.$refs.wgCarousel.setActiveItem(index)
          break
        case 1:
          that.gjSwipeIndex = index
          that.$refs.gjCarousel.setActiveItem(index)
          break
        case 2:
          that.nsSwipeIndex = index
          that.$refs.nsCarousel.setActiveItem(index)
          break
      }
    },
    onChangeCarousel(type, index) {
      const that = this
      switch (type) {
        case 0:
          that.wgSwipeIndex = index
          break
        case 1:
          that.gjSwipeIndex = index
          break
        case 2:
          that.nsSwipeIndex = index
          break
      }
    }
  }
}
</script>

<style lang="scss">
.ml_pc_report {
  padding: 10px 0 50px 0;
  width: 1200px;
  margin: 0 auto;

  .el-carousel__item {
    .ml_pc_report_imgfooter {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      padding: 5px 0;
      text-align: center;
      background-color: rgba(0, 0, 0, 0.8);
      color: #fff;
    }
  }

  .ml_pc_report_detection {
    position: absolute;
    bottom: 40px;
    right: 10px;
    z-index: 999;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.6);
    text-align: center;
    border-radius: 6px;

    .ml_pc_report_detection_title {
      border-right: 1px solid #fff;
      border-bottom: 1px solid #fff;
      padding: 5px;

      &:nth-child(4n + 4) {
        border-right: 0;
      }
    }

    .ml_pc_report_detection_value {
      border-right: 1px solid #fff;
      padding: 5px 0;
      color: #f1cc32;

      &:last-child {
        border: 0;
      }
    }
  }

  .ml_report_carimg {
    object-fit: cover;
    height: 100%;
    width: 100%;
    cursor: pointer;
  }

  .ml_report_carimg_detection {
    width: 100%;
    cursor: pointer;
    height: 100%;
  }

  .ml_pc_report_name {
    font-size: 20px;
    font-weight: bold;
    padding: 10px 0 20px 0;
  }

  .ml_pc_report_carinfo {
    background-color: #f8f8f8;
    padding: 15px 20px;
    text-align: center;
    margin: 20px 0;

    .el-col-6 {
      position: relative;

      &::after {
        content: "|";
        position: absolute;
        right: 0;
        top: 50%;
        color: #d3d3d3;
        font-size: 22px;
        font-weight: 50;
        margin-top: -11px;
      }

      &:last-child {
        &::after {
          content: "";
        }
      }

      div {
        &:first-child {
          padding-bottom: 10px;
        }

        &:last-child {
          color: #8d8d8d;
          font-size: 12px;
        }
      }
    }
  }

  .ml_pc_report_caraddress {
    // color: #8D8D8D;
    padding: 10px 0 20px 0;
  }

  .ml_pc_report_title {
    padding: 20px 0;
    font-size: 16px;

    &::before {
      content: "▌";
      color: #ff7901;
    }
  }

  .ml_detection_level_list {
    width: 50%;
    display: flex;
    color: #ff7901;
    text-align: center;
    margin: 0 auto;
    border: 1px solid #ff7901;
    border-radius: 3px;
    .ml_detection_level_item {
      flex: 1;
      padding: 5px 0;
      border-right: 1px solid #ff7901;
      &:last-child {
        border: 0;
      }

      .ml_icon_img {
        display: inline-block;
        width: 12px;
        vertical-align: middle;
        margin-top: -3px;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }

  .ml_pc_report_table {
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    tr {
      &:nth-child(2n + 2) {
        background-color: #f8f8f8;
      }
      td {
        padding: 15px 0;
        border: 1px solid #f1f1f1;
        line-height: 14px;
      }
    }
  }

  .ml_pc_report_summary {
    margin: 10px 0;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.05);
  }

  .el-icon-circle-close:before {
    color: #fff;
    font-size: 24px;
  }

  .ml_pc_report_content_title {
    background-color: #f6f6f6;
    padding: 10px 20px;
    margin-top: 20px;
  }

  .ml_pc_detection_img {
    position: relative;

    img {
      width: 100%;
    }

    .ml_circle {
      width: 12px;
      height: 12px;
      text-align: center;
      border-radius: 100%;
      position: absolute;
      color: #fff;
      cursor: pointer;
    }
  }

  .ml_detection_color_list {
    padding: 20px 0 10px 0;
    display: flex;
    color: #7d7d7d;

    .ml_detection_color_list_item {
      flex: 1;
      text-align: center;

      .ml_color_circle {
        width: 12px;
        height: 12px;
        border-radius: 100%;
        margin-right: 10px;
        display: inline-block;
      }
    }
  }

  .el-carousel__container {
    height: 438.75px;
  }

  .ml_detection_name {
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .ml_dot_detection {
    position: absolute;
    z-index: 9999;
    margin-top: -9px;
    margin-left: -5px;

    .ml_dot_color {
      color: #797979;
      &::before {
        content: "●";
        font-size: 16px;
        padding-right: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      left: 15px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-right: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
      margin-left: 13px;
    }
  }

  .ml_dot_detection1 {
    position: absolute;
    z-index: 9999;

    .ml_dot_color {
      color: #797979;
      &::before {
        content: "●";
        font-size: 16px;
        padding-left: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      right: 30px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-left: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
    }
  }

  .ml_pc_detection_card {
    .ml_pc_detection_row {
      border-top: 1px solid #f1f1f1;
      border-left: 1px solid #f1f1f1;
    }

    .el-col-8 {
      border-bottom: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;

      .el-col-12 {
        padding: 10px;

        &:first-child {
          background-color: #f8f8f8;
        }
      }
    }
  }

  .ml_circle_outside {
    position: relative;
    width: 100%;
    height: 100%;

    .ml_circle_inside {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 12px;
      height: 12px;
      filter: alpha(opacity=30);
      -moz-opacity: 0.3;
      -khtml-opacity: 0.3;
      opacity: 0.3;
      border-radius: 100%;
      animation: mymove 1.5s infinite;
      -webkit-animation: mymove 1.5s infinite;
    }

    @keyframes mymove {
      from {
        width: 12px;
        height: 12px;
      }

      to {
        width: 24px;
        height: 24px;
      }
    }

    @-webkit-keyframes mymove {
      from {
        width: 12px;
        height: 12px;
      }

      to {
        width: 24px;
        height: 24px;
      }
    }
  }

  .ml_view_img {
    .el-carousel__container {
      height: 585px;
    }

    .el-dialog__body,
    .el-dialog__header {
      padding: 0;
    }

    .el-dialog__headerbtn {
      right: -30px;
      color: #fff;
      top: 0;

      .el-icon-close:before {
        color: #fff;
        font-size: 20px;
      }
    }

    .ml_view_img_dialog_footer {
      text-align: left;
    }

    .ml_view_img_name {
      padding: 10px 0;

      .el-col {
        font-size: 18px;
      }
    }

    .ml_view_img_title {
      display: inline-block;
      padding: 3px 10px;
      color: #fff;
      border-radius: 3px;
      font-size: 12px;
    }

    .ml_view_img_value {
      display: inline-block;
      padding: 3px 10px;
      margin: 5px;
      font-size: 12px;
      color: #858585;
    }
  }

  .ml_pc_detection_list {
    background-color: #f8f8f8;
    padding: 10px 20px;

    .el-col-4 {
      padding: 10px 0;
      cursor: pointer;
    }
  }
}

.ml_pc_detection_list_popover {
  color: #fff;

  .el-carousel__container {
    height: 438.75px;
  }

  .ml_report_carimg_detection {
    width: 100%;
    height: 100%;
  }

  .ml_detection_name {
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .ml_dot_detection {
    position: absolute;
    z-index: 9999;
    margin-top: -9px;
    margin-left: -5px;

    .ml_dot_color {
      color: #797979;
      &::before {
        content: "●";
        font-size: 16px;
        padding-right: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      left: 15px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-right: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
      margin-left: 13px;
    }
  }

  .ml_dot_detection1 {
    position: absolute;
    z-index: 9999;

    .ml_dot_color {
      color: #797979;
      &::before {
        content: "●";
        font-size: 16px;
        padding-left: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      right: 30px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-left: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
    }
  }
}
</style>
