import { render, staticRenderFns } from "./component_detection_img_pc.vue?vue&type=template&id=2233e318&scoped=true"
import script from "./component_detection_img_pc.vue?vue&type=script&lang=js"
export * from "./component_detection_img_pc.vue?vue&type=script&lang=js"
import style0 from "./component_detection_img_pc.vue?vue&type=style&index=0&id=2233e318&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2233e318",
  null
  
)

export default component.exports