<!--
 * @Descripttion : 检测缺陷项-底部位置
 * @version      :
 * @Author       : ml
 * @Date         : 2020-08-12 11:04:30
 * @LastEditors  : min
 * @LastEditTime : 2022-11-10 14:58:12
-->
<template>
  <el-row class="ml_pc_detection_list">
    <template v-if="baseData.e_count>0">
      <el-col :span="4" v-for="(item,index) in baseData.clist" :key="index">
        <template v-if="getDefectImgCount(item.listimg)">
          <el-popover placement="top-start" width="585" trigger="hover" popper-class="ml_pc_detection_list_popover">
            <el-carousel :autoplay="false" indicator-position="none" arrow="always">
              <template v-for="(items,indexs) in item.listimg">
                <el-carousel-item :key="indexs" v-if="items.defect_image">
                  <img :src="items.defect_image+'?x-oss-process=image/resize,w_640,h_480/quality,q_90'" class="ml_report_carimg_detection">
                  <div class="ml_detection_name">
                    {{item.name}} ({{indexs+1}}/{{item.listimg.length}})
                  </div>
                  <template v-for="(itemss,indexss) in items.dlist">
                    <template v-if="itemss.x>0&&itemss.y>0">
                      <template v-if="itemss.x>0.5">
                        <div class="ml_dot_detection1" :style="{'left':itemss.x*100+'%','top':itemss.y*100+'%'}" :key="indexss">
                          <span class="ml_dot_color" :style="{'color':itemss.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_text">
                              {{itemss.name}}
                            </span>
                            <span class="ml_dot_triangle"></span>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <div class="ml_dot_detection" :style="{'left':itemss.x*100+'%','top':itemss.y*100+'%'}" :key="indexss">
                          <span class="ml_dot_color" :style="{'color':itemss.crvalue}"></span>
                          <div class="ml_dot_detection_text">
                            <span class="ml_dot_triangle"></span>
                            <span class="ml_dot_text">
                              {{itemss.name}}
                            </span>
                          </div>
                        </div>
                      </template>
                    </template>
                  </template>
                </el-carousel-item>
              </template>
            </el-carousel>
            <template slot="reference">
              <span>
                <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
                {{item.name}}
                <div>
                  <span v-for="(itemss,indexss) in item.crlist" :key="indexss" :style="{'background-color':itemss.crvalue?itemss.crvalue:'#d3d3d3'}" class="ml_view_img_title">{{itemss.defectname}}</span>
                </div>
              </span>
            </template>
          </el-popover>
        </template>
        <template v-else>
          <div>
            <img src="../../assets/004.png" style="display:inline-block;width:12px;margin-right:10px;" />
            {{item.name}}

            <div>
              <span v-for="(itemss,indexss) in item.crlist" :key="indexss" :style="{'background-color':itemss.crvalue?itemss.crvalue:'#d3d3d3'}" class="ml_view_img_title">{{itemss.defectname}}</span>
            </div>
          </div>
        </template>
      </el-col>
    </template>
  </el-row>
</template>
<script>
export default {
  props: {
    baseData: {
      type: Object
    }
  },
  data() {
    return {

    }
  },
  created() {

  },
  methods: {
    getDefectImgCount(arr) {
      // 获取图片总数
      let _imgCount = 0
      for (let i in arr) {
        if (arr[i].defect_image) {
          _imgCount++
        }
      }
      return _imgCount
    },
  }
}
</script>
<style lang="scss" scoped>
.ml_pc_detection_list {
  background-color: #f8f8f8;
  padding: 10px 20px;

  .el-col-4 {
    padding: 10px 0;
    cursor: pointer;
  }
}

.ml_pc_detection_list_popover {
  color: #fff;

  .el-carousel__container {
    height: 438.75px;
  }

  .ml_report_carimg_detection {
    width: 100%;
    height: 100%;
  }

  .ml_detection_name {
    text-align: center;
    padding: 5px 0;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    color: #fff;
  }

  .ml_dot_detection {
    position: absolute;
    z-index: 9999;
    margin-top: -9px;
    margin-left: -5px;

    .ml_dot_color {
      color: #797979;
      &::before {
        content: "●";
        font-size: 16px;
        padding-right: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      left: 15px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-right: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
      margin-left: 13px;
    }
  }

  .ml_dot_detection1 {
    position: absolute;
    z-index: 9999;

    .ml_dot_color {
      color: #797979;
      &::before {
        content: "●";
        font-size: 16px;
        padding-left: 8px;
      }
    }

    .ml_dot_detection_text {
      position: absolute;
      right: 30px;
      margin-top: -22px;
    }

    .ml_dot_triangle {
      &::before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border-top: 13px solid transparent;
        border-left: 13px solid rgba(0, 0, 0, 0.8);
        border-bottom: 13px solid transparent;
        display: inline-block;
      }
    }

    .ml_dot_text {
      color: #fff;
      background-color: rgba(0, 0, 0, 0.8);
      height: 26px;
      line-height: 26px;
      display: inline-block;
      white-space: nowrap;
      padding: 0 8px;
    }
  }
}

.ml_view_img_title {
  display: inline-block;
  padding: 3px 10px;
  color: #fff;
  border-radius: 3px;
  font-size: 12px;
  margin: 5px 5px 0 0;
}

.ml_view_img_value {
  display: inline-block;
  padding: 3px 10px;
  margin: 5px;
  font-size: 12px;
  color: #858585;
}
</style>
